html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  min-width: 960px;
  height: 207px;
  margin: 0px;
  padding: 0px;
  background: repeating-linear-gradient(
    -15deg,
    #f1f1f0,
    #f1f1f0 100px,
    #ececec 100px,
    #ececec 200px
  );
}

/* 
    This is set so that the footer is placed at the bottom of the screen if there isn't enough content to fill the screen 
    First child at the time of writing this is the header element
*/
body > div:nth-child(2) {
  min-height: calc(100vh - 5.1em);
}

/* body {
    height: 100vh;
    margin: 0px;
    padding: 0px;
    background: repeating-linear-gradient(-15deg, #AAA, #AAA 100px, #000 100px, #000 200px);
} */

* {
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.heat-result[data-animationon="1"] {
  animation: result-bg 3s 100ms ease-out;
}

.scoreboard-result[data-animationon="1"] {
  animation: pulse-live-heat 2s;
}

.heat-sub-result[data-animationon="1"] {
  animation: pulse-sub-result 3s 100ms ease-out;
}

.heat-rank[data-animationon="1"] {
  animation: pulse-rank 3s 100ms ease-out;
}

.hoverIcon {
  cursor: pointer;
  color: #374b5c;
}
.hoverIcon:hover {
  color: white;
}
@keyframes pulse-result {
  0% {
    scale: 1;
    transform-origin: right;
    color: #000000de;
  }
  20%,
  70% {
    scale: 1.2;
    transform-origin: right;
    color: #f4c647;
  }
  100% {
    scale: 1;
    transform-origin: right;
    color: #000000de;
  }
}

@keyframes result-bg {
  0% {
    scale: 1;
    transform-origin: right;
  }
  10%,
  20% {
    scale: 1.15;
    transform-origin: right;
    background-color: #374b5c;
    color: #ffffff;
  }
  100% {
    scale: 1;
    transform-origin: right;
  }
}

@keyframes pulse-live-heat {
  0% {
    scale: 1;
    transform-origin: right;
  }
  10%,
  80% {
    scale: 1.2;
    transform-origin: right;
  }
  100% {
    scale: 1;
    transform-origin: right;
  }
}

@keyframes superlive-heat-open {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 1;
    opacity: 1;
  }
}
@keyframes superlive-heat-close {
  0% {
    max-height: 1;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

@keyframes pulse-sub-result {
  0% {
  }
  10%,
  20% {
    background-color: #374b5c;
    color: #ffffff;
  }
  100% {
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(210, 52, 55, 1);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(210, 52, 55, 0);
  }
}

@keyframes pulse-animation-green {
  0% {
    box-shadow: 0 0 0 0px rgba(43, 107, 67, 1);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(43, 107, 67, 0);
  }
}

@keyframes pulse-animation-grey {
  0% {
    box-shadow: 0 0 0 0px rgba(63, 86, 105, 1);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(63, 86, 105, 0);
  }
}

@keyframes pulse-rank {
  0% {
  }
  10%,
  20% {
    background-color: #f4c647;
  }
  100% {
  }
}

@keyframes superlive-heat-box {
  0% {
    height: "0%";
  }
  100% {
    height: "100%";
  }
}
