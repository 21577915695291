/* .flag.size-m {
    width: 20px;
    height: 15px;
} */

.flag.size-m.border-radius {
    border-radius: 2px;
}

.flag.size-l.border-radius {
    border-radius: 4px !important;
}

/* SIZE FACTOR WxH: 0.75 */
/* .flag.size-l {
    width: 40px;
    height: 30px;
} */

/* .flag.size-l {
    width: 32px;
    height: 24px;
} */